import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Grid } from '@mui/material';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { BusinessUnitEnvironment } from 'Settings/interfaces/BusinessUnitEnvironment';
import {
  useCreateNotificationRuleMutation,
  useFetchNotificationRuleQuery,
  useUpdateNotificationRuleMutation,
} from 'Settings/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AccordionSection } from 'shared/components/AccordionSection/AccordionSection';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { ContentSection } from 'shared/components/ContentSection/ContentSection';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { InputLabelWrapper } from 'shared/components/InputLabelWrapper/InputLabelWrapper';
import { SelectChipGroup } from 'shared/components/SelectChipGroup';
import {
  SelectChipCategory,
  SelectChipChangePayload,
} from 'shared/components/SelectChipGroup/SelectChipGroup';
import {
  notificationRuleStatusItems,
  notificationRuleFormRequiredFields,
  notificationRuleSchema,
  notificationRuleExceptionTriggerOptions,
  notificationRuleSLATriggerOptions,
  notificationRuleTicketTriggerOptions,
  notificationRuleConditionOptions,
} from 'shared/fixtures/data/notification-rule.data';
import {
  NotificationRuleAdditionalFormData,
  NotificationRuleDataHandler,
  NotificationRuleDefaultFormData,
} from 'shared/handlers/notification-rule-data.handler';
import { useFetchNotificationSettingsOptionItems } from 'shared/hooks/useFetchNotificationSettingsOptionItems';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  NotificationRule,
  NotificationRuleTrigger,
  TeamRoleOptionsList,
  UserRoleOptionsList,
} from 'shared/models/data/notification-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import NotificationRuleRoleAutocomplete from 'Settings/components/NotificationRuleRoleAutocomplete';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import FilterChipGroup from 'shared/components/DataFilters/FilterChipGroup';
import {
  CategoryState,
  MultiSelectState,
} from 'shared/models/data/data-filter.model';
import { useGetLabelsMutation } from 'shared/store/api';
import Autocomplete from 'Common/components/Autocomplete';
import BulkActionDropdown from 'FindingDetails/components/FindingContent/components/BulkActionDropdown';
import {
  SystemLabel,
  SystemLabelType,
} from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';

interface NotificationRuleDrawerPanelProps extends BaseComponentProps {
  onCancel?: () => void;
}

const notificationRuleDataHandler = new NotificationRuleDataHandler();

export const NotificationRuleDrawerPanel: FunctionComponent<
  NotificationRuleDrawerPanelProps
> = ({ onCancel }) => {
  const [searchParams] = useQueryParams();

  const permissionActions = usePermissions();

  const { t: translation } = useTranslation();

  const { teamOptionList, emailOptionList } =
    useFetchNotificationSettingsOptionItems();

  const [selectedLabels, setSelectedLabels] = useState<
    Array<AutocompleteOption>
  >([]);

  const [labelAnchorEl, setLabelAnchorEl] = useState<HTMLDivElement | null>(
    null
  );

  const [
    getLabelItems,
    { data: labelItems, isLoading: labelItemsLoading, error: labelItemsError },
  ] = useGetLabelsMutation();

  const [selectedEmailList, setSelectedEmailList] = useState<
    Array<AutocompleteOption>
  >([]);
  const [selectedEmailRolesList, setSelectedEmailRolesList] = useState<
    Array<AutocompleteOption>
  >([]);

  const [selectedTeamRolesList, setSelectedTeamRolesList] = useState<
    Array<AutocompleteOption>
  >([]);
  const [selectedTeamList, setSelectedTeamList] = useState<
    Array<AutocompleteOption>
  >([]);

  const [environmentTypeMismatchError, setEnvironmentTypeMismatchError] =
    useState<boolean>(false);

  const [additionalFormData, setAdditionalFormData] =
    useState<NotificationRuleAdditionalFormData>({
      emails: [],
      teams: [],
      emailRoles: [],
      teamRoles: [],
      properties: {},
      resourceOwner: false,
      openTicket: false,
      closedTicket: false,
      triggers: [],
      labels: [],
    });

  const [formCancelationModalOpen, setFormCancelationModalOpen] =
    useState<boolean>(false);

  const labelSelectHandler = (options: Array<AutocompleteOption>) => {
    setSelectedLabels(options);
  };

  const hasExceptionsReadPermission = permissionActions.includes(
    ApplicationPermission.EXCEPTIONS_READ
  );

  const updatedNotificationRuleStatusItems = useMemo<
    Array<SelectChipCategory>
  >(() => {
    if (hasExceptionsReadPermission) {
      return [
        {
          ...notificationRuleStatusItems[0],
          optionList: [
            ...notificationRuleStatusItems[0].optionList,
            ...notificationRuleExceptionTriggerOptions,
            ...notificationRuleTicketTriggerOptions,
            ...notificationRuleSLATriggerOptions,
          ],
        },
      ];
    }
    return notificationRuleStatusItems;
  }, []);

  const { data: notificationRule, isFetching: notificationRuleLoading } =
    useFetchNotificationRuleQuery(searchParams?.ruleId as string, {
      skip: !searchParams?.ruleId,
      refetchOnMountOrArgChange: true,
    });

  const defaultFormValues = useMemo(() => {
    if (notificationRule) {
      return notificationRuleDataHandler.transformNotificationRuleDataToFormData(
        notificationRule
      );
    }

    return undefined;
  }, [notificationRule, updatedNotificationRuleStatusItems]);

  const [
    createNotificationRule,
    {
      isLoading: createNotificationRuleLoading,
      isSuccess: createNotificationRuleSuccess,
    },
  ] = useCreateNotificationRuleMutation();

  const [
    updateNotificationRule,
    {
      isLoading: updateNotificationRuleLoading,
      isSuccess: updateNotificationRuleSuccess,
    },
  ] = useUpdateNotificationRuleMutation();

  useEffect(() => {
    getLabelItems({
      queryString: 'orderBy=label&orderType=ASC&skip=0&take=10000',
    });
  }, []);

  useEffect(() => {
    if (createNotificationRuleSuccess || updateNotificationRuleSuccess) {
      onCancel && onCancel();
    }
  }, [createNotificationRuleSuccess, updateNotificationRuleSuccess]);

  useEffect(() => {
    if (defaultFormValues) {
      setAdditionalFormData({
        ...defaultFormValues,
      });

      setSelectedEmailList(defaultFormValues.emails);
      setSelectedTeamList(defaultFormValues.teams);
      setSelectedEmailRolesList(defaultFormValues.emailRoles);
      setSelectedTeamRolesList(defaultFormValues.teamRoles);
      setSelectedLabels(defaultFormValues.labels);

      if (defaultFormValues.project) {
        setFormValue('project', defaultFormValues.project);
      }

      for (const formValueKey in defaultFormValues) {
        if (
          formValueKey === 'advanceFilterItems' ||
          formValueKey === 'triggers' ||
          formValueKey === 'emails'
        ) {
          return;
        } else {
          setFormValue(
            formValueKey as any,
            defaultFormValues[
              formValueKey as keyof NotificationRuleDefaultFormData
            ]
          );
        }
      }
    }
  }, [defaultFormValues]);

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    setError,
    getValues,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(notificationRuleSchema),
  });

  const validateEnvironmentType = (
    environmentTypeSelections?: Array<AutocompleteOption>,
    businessUnitSelections?: Array<AutocompleteOption>
  ) => {
    if (environmentTypeSelections?.length && businessUnitSelections?.length) {
      for (const businessUnitSelection of businessUnitSelections) {
        const businessUnitIncludesEnvironmentType =
          businessUnitSelection.configurationParameters?.environments?.findIndex(
            (environment: BusinessUnitEnvironment) => {
              return environmentTypeSelections.some(
                (environmentSelection) =>
                  environmentSelection.value === environment.type
              );
            }
          ) > -1;

        if (businessUnitIncludesEnvironmentType === false) {
          setEnvironmentTypeMismatchError(true);
          setError('properties', {
            message: 'BU not associated with chosen environment',
          });
          return;
        }
      }

      for (const environmentTypeSelection of environmentTypeSelections) {
        const isIncludedInBusinessUnit =
          businessUnitSelections.findIndex((businessUnitSelection) => {
            return businessUnitSelection.configurationParameters?.environments?.some(
              (environment: BusinessUnitEnvironment) =>
                environment.type === environmentTypeSelection.value
            );
          }) > -1;

        if (isIncludedInBusinessUnit === false) {
          setEnvironmentTypeMismatchError(true);
          setError('properties', {
            message: 'BU not associated with chosen environment',
          });
          return;
        }
      }
    }

    clearErrors('properties');
    setEnvironmentTypeMismatchError(false);
  };

  const validateFormBeforeSubmit = () => {
    const formValues = getValues();

    let isValid = true;

    const isPropertyChecked =
      Object.keys(additionalFormData.properties).length > 0;

    if (isPropertyChecked) {
      if (environmentTypeMismatchError) {
        setError('properties', {
          message: 'BU not associated with chosen environment',
        });
        isValid = false;
      } else {
        clearErrors('properties');
      }
    } else {
      setError('properties', { message: 'Properties are required' });
      isValid = false;
    }

    if (additionalFormData.triggers.length === 0) {
      setError('triggers', { message: 'Triggers are required' });
      isValid = false;
    } else {
      clearErrors('triggers');
    }

    for (const requiredField of notificationRuleFormRequiredFields) {
      if (formValues[requiredField]?.length === 0) {
        setError(requiredField as any, {
          message: `${requiredField} is required`,
        });
        isValid = false;
      } else {
        clearErrors(requiredField as any);
      }
    }

    return isValid;
  };

  const isTicketingConfigured = useMemo(() => {
    return (
      defaultFormValues?.openTicket ||
      defaultFormValues?.closedTicket ||
      !!defaultFormValues?.project
    );
  }, [defaultFormValues]);

  const isCommunicationConfigured = useMemo(() => {
    const hasEmails =
      !!defaultFormValues?.emails?.length || !!defaultFormValues?.teams?.length;
    const hasRoles =
      !!defaultFormValues?.emailRoles?.length ||
      !!defaultFormValues?.teamRoles?.length;
    return hasEmails || hasRoles;
  }, [defaultFormValues]);

  const handleTeamRoleChange = (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => {
    setSelectedTeamRolesList(valueList as Array<AutocompleteOption>);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        teamRoles: valueList as Array<AutocompleteOption>,
      };
    });
  };

  const handleTeamChange = (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => {
    setSelectedTeamList(valueList as Array<AutocompleteOption>);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        teams: valueList as Array<AutocompleteOption>,
      };
    });
  };

  const handleEmailChange = (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => {
    setSelectedEmailList(valueList as Array<AutocompleteOption>);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        emails: valueList as Array<AutocompleteOption>,
      };
    });
  };

  const handleEmailRoleChange = (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => {
    setSelectedEmailRolesList(valueList as Array<AutocompleteOption>);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        emailRoles: valueList as Array<AutocompleteOption>,
      };
    });
  };

  const handleLabelsChange = (valueList: AutocompleteOption[]) => {
    setSelectedLabels(valueList);
    setAdditionalFormData((prevAdditionalFormData) => {
      return {
        ...prevAdditionalFormData,
        labels: valueList,
      };
    });
  };

  return notificationRuleLoading ? (
    <div className="notification-rule-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="notification-rule-view-panel">
      <CommonModalContainer
        handleClose={() => {
          setFormCancelationModalOpen(false);
        }}
        isOpen={formCancelationModalOpen}
        title={`Cancel Notification Rule ${
          notificationRule ? 'Update' : 'Creation'
        }`}
      >
        <div className="business-unit-form-modal-body">
          <div className="business-unit-form-modal-description">
            <span>Are you sure you want to cancel this action?</span>
            <span>All data will be lost</span>
          </div>
          <div className="business-unit-form-modal-buttons">
            <Button
              className="base-opus-text-button"
              onClick={() => {
                setFormCancelationModalOpen(false);
              }}
            >
              Stay
            </Button>
            <Button
              className="base-opus-text-button"
              onClick={() => {
                setFormCancelationModalOpen(false);
                onCancel && onCancel();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </CommonModalContainer>
      <div className="notification-rule-view-panel-header">
        <div className="notification-rule-view-panel-header-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />
        </div>
        <div className="notification-rule-view-panel-header-text">
          <div className="notification-rule-view-panel-header-title">
            Notification Rule {notificationRule ? 'Update' : 'Creation'}
          </div>
          <div className="notification-rule-view-panel-header-description">
            Group your organization's resources to align with service goals
          </div>
        </div>
      </div>

      <div className="notification-rule-view-panel-body">
        <form className="notification-rule-view-panel-form">
          <ContentSection
            title=""
            rootClassName="notification-rule-content-section notification-rule-base-content-section"
          >
            <InputLabelWrapper label="Name">
              <input
                type="text"
                className={`text-field-1 ${
                  errors.name ? 'input-validation-error-1' : ''
                }`}
                {...register('name')}
              ></input>
            </InputLabelWrapper>
            <InputLabelWrapper label="Description">
              <input
                type="text"
                className={`text-field-1`}
                {...register('description')}
              ></input>
            </InputLabelWrapper>
            <InputLabelWrapper label="Condition">
              <SelectChipGroup
                rootClassName={
                  errors.triggers ? 'input-validation-error-1' : ''
                }
                categories={updatedNotificationRuleStatusItems}
                labels={{
                  addLabel: 'When',
                }}
                displayMode="single"
                onChange={(payload: SelectChipChangePayload) => {
                  setAdditionalFormData((prevAdditionalFormData) => {
                    const hasFindingOpen = payload['status']?.some(
                      (valueItem) =>
                        valueItem.value ===
                        NotificationRuleTrigger.FINDING_CREATED
                    );
                    const hasFindingClosed = payload['status']?.some(
                      (valueItem) =>
                        valueItem.value ===
                        NotificationRuleTrigger.FINDING_CLOSED
                    );
                    return {
                      ...prevAdditionalFormData,
                      triggers: payload['status'] || [],
                      openTicket: hasFindingOpen
                        ? prevAdditionalFormData.openTicket
                        : false,
                      closedTicket: hasFindingClosed
                        ? prevAdditionalFormData.closedTicket
                        : false,
                    };
                  });
                }}
                defaultValues={{
                  status: additionalFormData.triggers,
                }}
              />
            </InputLabelWrapper>
            <InputLabelWrapper label="Match Filter">
              <div
                className={`select-chip-group-container notification-rule-filter-chip-group ${
                  errors.properties ? 'input-validation-error-1' : ''
                }`}
              >
                <FilterChipGroup
                  separator="AND"
                  categories={notificationRuleConditionOptions}
                  categoryState={additionalFormData.properties}
                  onChange={(id: string, state: CategoryState) => {
                    setAdditionalFormData((prevAdditionalFormData) => {
                      const updatedProperties = {
                        ...prevAdditionalFormData.properties,
                      };

                      const typedState = state as MultiSelectState;

                      if (typedState.selectedOptions?.length === 0) {
                        if (updatedProperties[id]) delete updatedProperties[id];
                      } else {
                        updatedProperties[id] = state;
                      }

                      return {
                        ...prevAdditionalFormData,
                        properties: updatedProperties,
                      };
                    });
                  }}
                  Components={{
                    AddButtonContent: (
                      <>
                        <span>Add</span>
                        <OpusSvgIcon
                          type={SVG_ICON_TYPES.SORT_DESCENDING_ICON}
                        />
                      </>
                    ),
                  }}
                  addButtonAlignment="right"
                  onRemoveChip={(categoryId: string) => {
                    setAdditionalFormData((prevAdditionalFormData) => {
                      const updatedProperties = {
                        ...prevAdditionalFormData.properties,
                      };

                      if (updatedProperties[categoryId])
                        delete updatedProperties[categoryId];

                      return {
                        ...prevAdditionalFormData,
                        properties: updatedProperties,
                      };
                    });
                  }}
                />
              </div>
              {environmentTypeMismatchError ? (
                <div className="notification-rule-validation-error-text">
                  <span>
                    {translation(
                      `settings.details.notificationRuleEnvironmentMismatchErrorMessage`
                    )}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </InputLabelWrapper>
          </ContentSection>
          <div className="notification-rule-form-panel-form-divider"></div>

          <AccordionSection
            title="Communication"
            rootClassName="notification-rule-content-section"
            sectionIcon={
              <OpusSvgIcon type={SVG_ICON_TYPES.SATELLITE_DISH_ICON} />
            }
            defaultExpanded={isCommunicationConfigured}
          >
            <InputLabelWrapper
              label="Send an Email"
              showCheckbox
              defaultChecked={
                additionalFormData.emails?.length > 0 ||
                additionalFormData.emailRoles?.length > 0
              }
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  emails: checked ? selectedEmailList : [],
                  emailRoles: checked ? selectedEmailRolesList : [],
                }));
              }}
              renderBody={(checked: boolean) => (
                <NotificationRuleRoleAutocomplete
                  model="emails"
                  onEmailTeamChange={handleEmailChange}
                  onRoleChange={handleEmailRoleChange}
                  mainOptionList={emailOptionList}
                  roleOptionList={UserRoleOptionsList}
                  limitTags={15}
                  mainValues={selectedEmailList}
                  roleValues={selectedEmailRolesList}
                  initialMainSelectedValues={defaultFormValues?.emails}
                  initialRoleSelectedValues={defaultFormValues?.emailRoles}
                  enableCheckbox
                  areOptionsLoaded
                  disabled={!checked}
                  errors={errors} // Your error object
                  checked={checked}
                />
              )}
            ></InputLabelWrapper>

            <InputLabelWrapper
              label="Send a message to"
              showCheckbox
              defaultChecked={
                additionalFormData.teams.length > 0 ||
                additionalFormData.teamRoles?.length > 0
              }
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  teams: checked ? selectedTeamList : [],
                  teamRoles: checked ? selectedTeamRolesList : [],
                }));
              }}
              renderBody={(checked: boolean) => (
                <NotificationRuleRoleAutocomplete
                  model="teams"
                  onEmailTeamChange={handleTeamChange}
                  onRoleChange={handleTeamRoleChange}
                  mainOptionList={teamOptionList}
                  roleOptionList={TeamRoleOptionsList}
                  limitTags={15}
                  mainValues={selectedTeamList}
                  roleValues={selectedTeamRolesList}
                  initialMainSelectedValues={defaultFormValues?.teams}
                  initialRoleSelectedValues={defaultFormValues?.teamRoles}
                  enableCheckbox
                  areOptionsLoaded
                  disabled={!checked}
                  errors={errors}
                  checked={checked}
                />
              )}
            ></InputLabelWrapper>

            {/* <InputLabelWrapper
              label="Resource Owner"
              showCheckbox
              defaultChecked={additionalFormData.resourceOwner}
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  resourceOwner: checked,
                }));
              }}
            ></InputLabelWrapper> */}
          </AccordionSection>
          <div className="notification-rule-form-panel-form-divider"></div>
          <AccordionSection
            title="Project Management"
            rootClassName="notification-rule-content-section"
            sectionIcon={
              <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON} />
            }
            defaultExpanded={isTicketingConfigured}
          >
            <InputLabelWrapper
              label="Open Ticket"
              showCheckbox
              defaultChecked={additionalFormData.openTicket}
              disabled={
                !additionalFormData.triggers.find(
                  (trigger) =>
                    trigger.value === NotificationRuleTrigger.FINDING_CREATED
                )
              }
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  openTicket: checked,
                }));
              }}
            ></InputLabelWrapper>
            <InputLabelWrapper
              label="Close Ticket"
              showCheckbox
              defaultChecked={additionalFormData.closedTicket}
              disabled={
                !additionalFormData.triggers.find(
                  (trigger) =>
                    trigger.value === NotificationRuleTrigger.FINDING_CLOSED
                )
              }
              onToggle={(checked: boolean) => {
                setAdditionalFormData((prevAdditionalFormData) => ({
                  ...prevAdditionalFormData,
                  closedTicket: checked,
                }));
              }}
            ></InputLabelWrapper>

            <InputLabelWrapper label="Project" isOptional>
              <input
                disabled={
                  !additionalFormData.triggers.find(
                    (trigger) =>
                      trigger.value === NotificationRuleTrigger.FINDING_CREATED
                  )
                }
                type="text"
                className={`text-field-1`}
                {...register('project')}
              ></input>
            </InputLabelWrapper>
          </AccordionSection>
          <div className="notification-rule-form-panel-form-divider"></div>

          <AccordionSection
            title="Labels"
            rootClassName="notification-rule-content-section notification-rule-content-labels-section"
            sectionIcon={<OpusSvgIcon type={SVG_ICON_TYPES.TAGS_ICON} />}
            defaultExpanded={
              defaultFormValues?.labels && defaultFormValues.labels.length > 0
            }
          >
            <InputLabelWrapper label="Select labels">
              <div
                className="notification-rule-content-labels-section-input"
                onClick={(event) => {
                  setLabelAnchorEl(event.currentTarget);
                }}
              >
                <Autocomplete
                  model="value"
                  optionList={labelItems}
                  loading={labelItemsLoading}
                  onChangeCallBack={(model, value) => {
                    const values = value as Array<AutocompleteOption>;

                    handleLabelsChange(values);
                  }}
                  areOptionsLoaded
                  classes={{
                    root: 'multi-select-field-1',
                    paper: 'multi-select-field-paper-1',
                  }}
                  enableCheckbox
                  placeholder={translation(`labels.pleaseSelectLabels`)}
                  disableDropdown
                  limitTags={4}
                  values={selectedLabels}
                  initialSelectedValues={selectedLabels}
                />
              </div>
            </InputLabelWrapper>

            <BulkActionDropdown
              open={Boolean(labelAnchorEl)}
              handleClose={() => {
                setLabelAnchorEl(null);
              }}
              anchorEl={labelAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              itemType={SystemLabelType.FINDING}
              onSelectLabels={(labels: Array<SystemLabel>) => {
                handleLabelsChange(
                  labels.map((labelItem) => ({
                    value: labelItem.label,
                    label: labelItem.label,
                  }))
                );
              }}
              selectedLabels={selectedLabels.map((selectedLabel) => ({
                label: selectedLabel.value,
              }))}
            />
          </AccordionSection>
        </form>
      </div>

      <div className="notification-rule-view-panel-footer">
        <div className="notification-rule-view-panel-footer-validation-error">
          {Object.keys(errors)?.length ? (
            <div className="notification-rule-validation-error-text">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />
              <span>
                {environmentTypeMismatchError
                  ? translation(
                      `settings.details.notificationRuleFormValidationErrorMessage`
                    )
                  : translation(
                      `settings.details.notificationRuleFormRequiredErrorMessage`
                    )}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="notification-rule-view-panel-footer-buttons">
          <Button
            className="opus-secondary-button"
            onClick={() => {
              setFormCancelationModalOpen(true);
            }}
          >
            Cancel
          </Button>
          <Button
            className="opus-primary-button"
            onClick={() => {
              const isValid = validateFormBeforeSubmit();

              if (isValid) {
                const formValues = getValues();
                const notificationRuleData: NotificationRule =
                  notificationRuleDataHandler.transformNotificationRuleFormDataToPostData(
                    formValues as any,
                    additionalFormData,
                    notificationRuleConditionOptions
                  );
                if (notificationRule) {
                  updateNotificationRule({
                    id: notificationRule.id,
                    ...notificationRuleData,
                  });
                } else {
                  createNotificationRule(notificationRuleData);
                }
              }
            }}
          >
            {createNotificationRuleLoading || updateNotificationRuleLoading
              ? 'Saving'
              : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};
